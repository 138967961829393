// addons/rule.js
'use strict';

export const addon = renderer => {
	renderer.rule = (css, blockName) => {
		const block = `${renderer.pfx}${blockName || renderer.hash(css)}`;
		renderer.put(`.${block}`, css);
		return ` ${block}`;
	};
};
