import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';

import styles from './styles';
import { SPACINGS } from './constants';

export const Grid = React.forwardRef((props, ref) => {
	const {
		alignContent = 'stretch',
		alignItems = 'stretch',
		classes,
		className: classNameProp,
		component: Component = 'div',
		container = false,
		direction = 'row',
		item = false,
		justify = 'flex-start',
		lg = false,
		md = false,
		sm = false,
		spacing = 0,
		wrap = 'wrap',
		xl = false,
		xs = false,
		zeroMinWidth = false,
		noPaddingX = false,
		...rest
	} = props;

	const className = classNames(
		classes.root,
		{
			[classes.container]: container,
			[classes.item]: item,
			[classes.zeroMinWidth]: zeroMinWidth,
			[classes[`spacing-xs-${String(spacing)}`]]: container && spacing !== 0,
			[classes[`direction-xs-${String(direction)}`]]: direction !== 'row',
			[classes[`wrap-xs-${String(wrap)}`]]: wrap !== 'wrap',
			[classes[`align-items-xs-${String(alignItems)}`]]: alignItems !== 'stretch',
			[classes[`align-content-xs-${String(alignContent)}`]]: alignContent !== 'stretch',
			[classes[`justify-xs-${String(justify)}`]]: justify !== 'flex-start',
			[classes[`grid-xs-${String(xs)}`]]: xs !== false,
			[classes[`grid-sm-${String(sm)}`]]: sm !== false,
			[classes[`grid-md-${String(md)}`]]: md !== false,
			[classes[`grid-lg-${String(lg)}`]]: lg !== false,
			[classes[`grid-xl-${String(xl)}`]]: xl !== false,
			[classes.noPaddingX]: noPaddingX,
		},
		classNameProp
	);

	return <Component className={className} ref={ref} {...rest} />;
});

Grid.displayName = 'Grid';

Grid.propTypes = {
	alignContent: PropTypes.oneOf(['stretch', 'center', 'flex-start', 'flex-end', 'space-between', 'space-around']),
	alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	component: PropTypes.elementType,
	container: PropTypes.bool,
	direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
	item: PropTypes.bool,
	justify: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly']),
	spacing: PropTypes.oneOf(SPACINGS),
	wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
	xl: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	lg: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	md: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	sm: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	xs: PropTypes.oneOf([false, 'auto', true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
	zeroMinWidth: PropTypes.bool,
	noPaddingX: PropTypes.bool,
};

export default withNano(styles)(Grid);
