import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withNano from '../../withNano';

import styles from './styles';
import theme from '../../withTheme/theme';

import omit from '../../utils/omit';

const Icon = ({
	color,
	disabledColor,
	hoverColor,
	children,
	className,
	classes,
	size,
	width,
	height,
	viewBox,
	onClick,
	disabled,
	...props
}) => {
	const [currentColor, setColor] = useState(color);
	const clickable = !!onClick;
	useEffect(() => {
		if (disabled) {
			setColor(disabledColor);
			return;
		}
		setColor(color);
	}, [color, disabled, disabledColor]);
	const rest = omit(props, ['activeColor', 'disabledColor', 'hoverColor']);
	return (
		<svg
			className={classNames(
				className,
				classes.root,
				size && classes.customSize,
				// color && classes.customColor,
				clickable && hoverColor && classes.hoverColor,
				clickable && classes.clickable
			)}
			onClick={e => !disabled && onClick && onClick(e)}
			onMouseEnter={() => !disabled && clickable && setColor(hoverColor)}
			onMouseLeave={() => !disabled && clickable && setColor(color)}
			width={width}
			height={height}
			viewBox={viewBox}
			{...rest}>
			{typeof children === 'function'
				? children(
						typeof currentColor === 'string' ? { primary: theme.palette[currentColor] || currentColor } : currentColor
				  )
				: children}
		</svg>
	);
};

const colorPropType = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.shape({
		primary: PropTypes.string,
		secondary: PropTypes.string,
		border: PropTypes.string,
		background: PropTypes.string,
	}),
]);

Icon.displayName = 'Icon';

Icon.propTypes = {
	color: colorPropType,
	disabledColor: colorPropType,
	activeColor: colorPropType,
	hoverColor: colorPropType,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.func]),
	className: PropTypes.string,
	classes: PropTypes.object,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	// size can be "lg/md/sm" or number {12}
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	viewBox: PropTypes.string,
};

Icon.defaultProps = {
	color: theme.palette.grey90,
	hoverColor: theme.palette.black,
	disabled: false,
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

export default withNano(styles, {
	observableProps: ['size', 'color', 'hoverColor'],
})(Icon);
