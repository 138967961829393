export default theme => {
	const { typography, palette, transitions, spacing } = theme;
	return {
		inputContainer: {
			display: 'flex',
		},
		flagSelectContainer: {
			position: 'relative',
			display: 'inline-flex',
			alignItems: 'center',
			border: `solid 1px ${palette.grey90}`,
			borderRadius: '4px 0 0 4px',
			padding: '4px',
			background: '#fafafa',
			cursor: 'pointer',
			'&:hover': {
				background: '#eaeaea',
			},
		},
		mobileSelect: {
			position: 'absolute',
			top: 0,
			left: 0,
			height: '100%',
			width: '100%',
			opacity: 0,
			zIndex: 10,
			'@media screen and (min-width: 768px)': {
				display: 'none',
			},
		},
		flagIcon: {
			display: 'inline-flex',
		},
		countryCodePreview: {
			display: 'inline-flex',
			margin: '0 3px 0 5px',
		},
		arrow: {
			transform: 'rotate(90deg)',
			height: '12px',
			width: '12px',
		},
		countrySearchInput: {
			width: '280px',
			padding: '4px',
			display: 'block',
			margin: 'auto',
			outline: 'none',
		},
		dropdownContainer: {
			marginTop: '-18px',
			border: '1px solid #bababa',
			width: '300px',
			height: 'auto',
			position: 'absolute',
			background: 'white',
			zIndex: '10',
			padding: '4px',
		},
		optionsContainer: {
			overflow: 'scroll',
			maxHeight: '200px',
			height: 'auto',
		},
		option: {
			display: 'flex',
			alignItems: 'center',
			padding: '2px',
			'&:hover': {
				cursor: 'pointer',
				background: '#ddd',
			},
		},
		flag: {
			height: '20px',
			margin: '2px 4px',
		},
		input: {
			borderLeft: 'none !important',
			width: 'calc(100% - 100px)',
			borderRadius: '0 4px 4px 0',
			border: `solid 1px ${palette.grey90}`,
			height: 20,
			padding: spacing(2),
			outline: 'none',
			transition: `${transitions.duration.short}ms ${transitions.easing.easeOut}`,
			fontSize: typography.fontSizeMd,
			fontWeight: typography.fontWeight,
			fontStyle: typography.fontStyle,
			fontStretch: typography.fontStretch,
			fontFamily: typography.fontFamily,
			lineHeight: typography.lineHeightMd,
			letterSpacing: 'normal',
			color: palette.black,
			boxSizing: 'content-box',
			'&::placeholder': {
				color: palette.grey70,
			},
			'&[type=text]': {
				'-webkit-appearance': 'none',
				'-moz-appearance': 'none',
				appearance: 'none',
			},
		},
		'input:disabled': {
			background: '#eaeaea',
			cursor: 'no-drop',
			border: 'none',
		},
		focused: {
			border: `solid 1px ${palette.blue}`,
		},
		error: {
			backgroundColor: 'rgba(208, 2, 27, 0.08)',
			border: `solid 1px ${palette.red}`,
		},
		complete: {
			border: `solid 1px ${palette.black}`,
		},
		container: {
			position: 'relative',
			margin: `${spacing(1)} 0`,
		},
		errorText: {},
		label: {
			lineHeight: typography.lineHeightLg,
			letterSpacing: 'normal',
		},
		labelText: {
			display: 'inline-block',
		},
		customSize: {
			width: ({ size }) => {
				if (typeof size === 'string') {
					if (size === 'sm') return 'calc(25% - 18px)';
					if (size === 'md') return 'calc(50% - 18px)';
					if (size === 'lg') return 'calc(80% - 18px)';
					if (size === 'xl') return 'calc(100% - 18px)';
				}
			},
		},
		requiredLabelHint: {
			verticalAlign: 'baseline',
			color: 'red',
		},
		optionalLabelHint: {
			display: 'inline',
			marginLeft: '4px',
		},
	};
};
