import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../withTheme/theme';
import Icon from '../Icon';

const Arrow = props => (
	<Icon name="arrow" {...props}>
		{({ primary, secondary, background, border }) => (
			<g fill={primary}>
				<path d="M7 18.12L13.18 12L7 5.88L8.903 4L17 12L8.903 20L7 18.12Z" />
			</g>
		)}
	</Icon>
);

const colorPropType = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.shape({
		primary: PropTypes.string,
		secondary: PropTypes.string,
		border: PropTypes.string,
		background: PropTypes.string,
	}),
]);

Arrow.defaultProps = {
	color: theme.palette.gery80,
	hoverColor: theme.palette.black,
	disabledColor: theme.palette.grey80,
	activeColor: theme.palette.black,
};

Arrow.displayName = 'Arrow';

Arrow.propTypes = {
	color: colorPropType,
	disabledColor: colorPropType,
	activeColor: colorPropType,
	hoverColor: colorPropType,
};

export default Arrow;
