export default theme => {
	const { icons } = theme;
	const customSize = ({ size }) => {
		if (size === 'sm') return icons.size.sm;
		if (size === 'md') return icons.size.md;
		if (size === 'lg') return icons.size.lg;
		if (typeof size === 'number') return size;
		return icons.size.default;
	};

	return {
		root: {
			objectFit: 'contain',
			height: icons.size.default,
			width: icons.size.default,
			fill: theme.palette.black,
			outline: 'none',
		},
		customSize: {
			height: customSize,
			width: customSize,
		},
		customColor: {
			fill: ({ color }) => theme.palette[color] || color,
		},
		clickable: {
			'&:hover': {
				cursor: 'pointer',
			},
		},
		hoverColor: {
			'&:hover': {
				fill: ({ hoverColor }) => theme.palette[hoverColor] || hoverColor,
			},
		},
	};
};
