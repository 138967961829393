import theme from './theme';

const withTheme = styles => {
	if (typeof styles === 'function') {
		return styles(theme);
	}
	return styles;
};

export default withTheme;
export { theme };
