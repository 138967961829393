export default theme => ({
	container: {
		position: 'relative',
	},
	inputBox: {
		position: 'relative',
		display: 'inline-block',
		width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
	},
	label: {
		lineHeight: '26px',
		letterSpacing: 'normal',
	},
	input: {
		height: '38px',
		fontSize: '14px',
		display: 'inline',
		boxSizing: 'border-box',
		padding: '8px 40px 8px 8px',
		textAlign: 'left',
		border: '1px solid #d6d6d6',
		borderRadius: '4px',
		backgroundColor: theme.palette.white,
		backgroundClip: 'padding-box',
		appearance: 'none',
		minHeight: '32px',
		outline: 'none',
		width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
	},
	noValue: {
		color: theme.palette.grey100,
	},
	selected: {
		boxShadow: '0 0 2px 0 rgba(32, 119, 213, 0.16)',
		borderColor: theme.palette.blue,
	},
	arrow: {
		pointerEvents: 'none',
		transform: 'rotate(90deg)',
		transition: 'transform 0.3s ease-in-out',
		position: 'absolute',
		right: '0',
		padding: '8px',
	},
	open: {
		transform: 'rotate(270deg)',
	},
	error: {
		backgroundColor: 'rgba(208, 2, 27, 0.08) !important',
		border: `solid 1px ${theme.palette.red} !important`,
	},
});
