export default theme => ({
	overlay: {
		position: 'fixed',
		left: '0',
		right: '0',
		top: '0',
		bottom: '0',
		transition: 'transform 0.3s ease-in-out',
		backgroundColor: 'rgba(255, 255, 255, 0.75)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	zIndex: ({ zIndex = 1000 }) => ({
		zIndex,
	}),
	closed: ({ openDirection = 'bottom' }) => {
		if (openDirection === 'top') return { transform: 'translateY(-100vh)' };
		if (openDirection === 'left') return { transform: 'translateX(-100vh)' };
		if (openDirection === 'right') return { transform: 'translateX(100vh)' };
		return { transform: 'translateY(100vh)' };
	},
	open: {
		transform: 'translateY(0)',
	},
	container: {
		width: '100%',
		height: '100%',
		position: 'fixed',
		left: '0',
		right: '0',
		top: '0',
		bottom: '0',
		[theme.breakpoints.up('sm')]: {
			position: 'relative',
			width: '490px',
			borderRadius: '4px',
			boxShadow: '0 2px 6px 0 rgba(36, 47, 51, 0.16)',
			border: 'solid 1px #FBFBFB',
			backgroundColor: theme.palette.white,
			maxHeight: '80%',
			height: 'auto',
			overflowY: 'scroll',
		},
	},
	exitIcon: {
		justifyContent: 'space-between',
	},
	arrowIcon: {
		justifyContent: 'flex-start',
	},

	header: {
		position: 'sticky',
		top: 0,
		display: 'flex',
		boxSizing: 'border-box',
		justifyContent: ({ closeIcon = 'exit' }) => (closeIcon === 'exit' ? 'space-between' : 'flex-start'),
		height: '56px',
		backgroundColor: theme.palette.white,
		borderBottom: 'solid 1px #d3d3db',
		padding: '16px',
		[theme.breakpoints.up('sm')]: {
			position: 'absolute',
			right: 0,
			justifyContent: 'flex-end',
			border: 'none',
			backgroundColor: 'transparent',
		},
		'> *': {
			marginRight: 8,
		},
	},
	exit: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',
		transition: 'transform 0.3s ease-in-out',
		outline: 'none',
		'&:hover': {
			transform: 'rotate(90deg)',
		},
	},
	content: {
		height: ({ noHeader = false }) => (noHeader ? '100%' : 'calc(100% - 56px)'),
		backgroundColor: ({ backgroundColor = 'white90' }) => theme.palette[backgroundColor] || backgroundColor,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'scroll',
		[theme.breakpoints.up('sm')]: {
			backgroundColor: 'white',
			height: '100%',
		},
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
});
