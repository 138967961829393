import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';
import styles from './styles';

import ExitIcon from '../../icons/Exit';
import ArrowIcon from '../../icons/BackArrow';
import Text from '../Text';

export const Modal = ({
	title,
	onClose,
	children,
	isOpen,
	classes,
	className,
	openDirection = 'bottom',
	closeIcon = 'exit',
	noHeader = false,
	backgroundColor,
	zIndex,
	...rest
}) => {
	const [show, setShow] = useState(isOpen);

	const modalNode = useRef(null);

	const handleClickOutsideCalendar = e => {
		if (modalNode.current && modalNode.current.contains && !modalNode.current.contains(e.target)) {
			onClose();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutsideCalendar, false);

		return () => {
			document.removeEventListener('mousedown', handleClickOutsideCalendar, false);
		};
	}, []);

	useEffect(() => {
		if (isOpen) {
			if (document) document.body.style.overflow = 'hidden';
			const timer = setTimeout(() => {
				setShow(true);
			}, 0);
			return () => clearTimeout(timer);
		} else {
			if (document) document.body.style.overflow = '';
			const timer = setTimeout(() => {
				setShow(false);
			}, 400);
			return () => clearTimeout(timer);
		}
	}, [isOpen]);

	const closeComponent = (
		<div
			className={classes.exit}
			onClick={onClose}
			onKeyPress={e => {
				if (e.keyCode === 'ESC') onClose();
			}}
			role="button"
			tabIndex={isOpen ? 0 : -1}>
			{closeIcon === 'arrow' ? <ArrowIcon color="black"></ArrowIcon> : <ExitIcon color="grey90" hoverColor="black" />}
		</div>
	);
	return isOpen || show
		? ReactDOM.createPortal(
				<div
					className={classNames(
						classes.overlay,
						show && isOpen ? classes.open : classes.closed,
						zIndex && classes.zIndex
					)}>
					<div className={classNames(classes.container, className)} {...rest} ref={modalNode}>
						{noHeader ? null : (
							<div className={classNames(classes.header)}>
								{onClose && closeIcon === 'arrow' && closeComponent}
								<div className={classes.title}>
									{typeof title === 'string' ? (
										<Text bold size="md">
											{title}
										</Text>
									) : (
										title
									)}
								</div>
								{onClose && closeIcon === 'exit' && closeComponent}
							</div>
						)}
						<div className={classes.content}>{children}</div>
					</div>
				</div>,
				document.body
		  )
		: null;
};

Modal.displayName = 'Modal';

Modal.defaultProps = {
	isOpen: true,
	openDirection: 'bottom',
	zIndex: 1003,
	closeIcon: 'exit',
};

Modal.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	onClose: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
	isOpen: PropTypes.bool,
	openDirection: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
	zIndex: PropTypes.number,
	closeIcon: PropTypes.oneOf(['exit', 'arrow']),
	classes: PropTypes.object,
	className: PropTypes.string,
	backgroundColor: PropTypes.string,
	noHeader: PropTypes.bool,
};

// export default withNano(styles, { observableProps: ['openDirection', 'zIndex', 'closeIcon'] })(Modal);
export default withNano(styles, {
	observableProps: ['openDirection', 'zIndex', 'closeIcon', 'noHeader', 'backgroundColor'],
})(Modal);
