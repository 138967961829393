import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';

import styles from './styles';

import Text from '../Text';

export class Tooltip extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			tooltipTop: 0,
			tooltipLeft: 0,
		};
		this.myInput = React.createRef();
		this.domBody = document.body;
	}

	componentDidMount = () => {
		const { elementID, position } = this.props;
		const element = document.getElementById(elementID);
		const height = this.myInput.current ? this.myInput.current.offsetHeight : 150;
		const width = this.myInput.current ? this.myInput.current.offsetWidth : 150;
		this.setState({ height, width });
		if (element) {
			element.addEventListener('mouseenter', e => {
				const { top, left } = this.getCoords(e.target);
				this.setState(prev => ({
					visible: true,
					...this.getTooltipPos(
						top,
						left,
						position,
						prev.height,
						prev.width,
						e.target.offsetHeight,
						e.target.offsetWidth
					),
				}));
			});
			element.addEventListener('mouseleave', () => this.setState({ visible: false }));
		}
	};

	getTooltipPos = (top, left, position, toolheight, toolwidth, elemheigth, elemWidth) => {
		if (position === 'top') return { tooltipTop: top - toolheight - 10, tooltipLeft: left };
		if (position === 'bottom') return { tooltipTop: top + elemheigth + 10, tooltipLeft: left };
		if (position === 'right') return { tooltipTop: top, tooltipLeft: left + elemWidth + 10 };
		if (position === 'left') return { tooltipTop: top, tooltipLeft: left - toolwidth - 10 };
	};

	getCoords = elem => {
		// crossbrowser version
		const box = elem.getBoundingClientRect();

		const { body } = document;
		const docEl = document.documentElement;

		const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
		const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

		const clientTop = docEl.clientTop || body.clientTop || 0;
		const clientLeft = docEl.clientLeft || body.clientLeft || 0;

		const top = box.top + scrollTop - clientTop;
		const left = box.left + scrollLeft - clientLeft;

		return { top: Math.round(top), left: Math.round(left) };
	};

	render = () => {
		const { classes, position, label } = this.props;
		const { tooltipTop, tooltipLeft, visible } = this.state;
		return ReactDOM.createPortal(
			<div
				id="tooltip"
				className={classes.root}
				style={{
					position: 'absolute',
					top: tooltipTop,
					left: tooltipLeft,
					zIndex: 1003, // On top of modal
					visibility: visible ? '' : 'hidden',
				}}
				ref={this.myInput}>
				<div
					className={classNames(
						classes.label,
						position === 'left' && classes.right,
						position === 'right' && classes.left,
						position === 'top' && classes.bottom,
						position === 'bottom' && classes.top
					)}>
					<Text>{label}</Text>
				</div>
			</div>,
			this.domBody
		);
	};
}

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
	classes: PropTypes.object,
	position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
	label: PropTypes.string.isRequired,
	elementID: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {
	position: 'left',
};

export default withNano(styles)(Tooltip);
