import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';
import omit from '../../utils/omit';
import Text from '../Text';
import ArrowIcon from '../../icons/Arrow';

import { parsePhoneNumberFromString, AsYouType, getExampleNumber } from 'libphonenumber-js/min';
import examples from 'libphonenumber-js/examples.mobile.json';
import Flags from 'country-flag-icons/react/3x2';
import { isMobile } from 'react-device-detect';

import countries from './countriesData';

import styles from './styles';

const DROPDOWN_SIZE = 200;
const COMPONENT_HEIGHT = 58;

const firstDifferenceIndex = (str1, str2) => {
	for (let i = 0; i < Math.max(str1.length, str2.length); i++) {
		if (str1[i] != str2[i]) return i;
	}
	return -1;
};

const deleteFirstNumberBeforeIndex = (str, index) => {
	for (let i = index; i >= 0; i--) {
		if (!isNaN(Number(str[i])) && !isNaN(parseInt(str[i]))) return str.slice(0, i) + str.slice(i + 1);
	}
	return str;
};

const removeCountryPrefixFromNumber = (phone, country) => {
	const countryCode = `+${countries[country].areaCode}`;
	if (phone.slice(0, countryCode.length) === countryCode) return phone.slice(countryCode.length);
	return phone;
};

export class PhoneInput extends Component {
	constructor(props) {
		super(props);
		const phoneNumber = props.value ? parsePhoneNumberFromString(props.value) || {} : {};

		const country = (() => {
			if (phoneNumber.country) return phoneNumber.country;
			if (countries[props.defaultCountry]) return props.defaultCountry;
			return 'US';
		})();

		const inputValue = props.value
			? removeCountryPrefixFromNumber(new AsYouType(country).input(props.value), country).trim()
			: '';

		this.state = {
			focused: false,
			touched: false,
			changed: false,
			country: country,
			isCountryListOpen: false,
			countriesFilter: '',
			inputValue,
		};
	}

	componentDidMount = () => {
		window.addEventListener('mousedown', this.handleClickOutsideSelect, false);
	};

	componentWillUnmount = () => {
		window.removeEventListener('mousedown', this.handleClickOutsideSelect, false);
	};

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			const phoneNumber = parsePhoneNumberFromString(this.props.value) || {};
			const country = phoneNumber.country || this.state.country || this.props.defaultCountry;
			const inputValue = removeCountryPrefixFromNumber(new AsYouType(country).input(this.props.value), country).trim();
			this.setState({ country, inputValue });
		}
	}

	handleClickOutsideSelect = e => {
		if (
			this.flagSelectorRef &&
			this.dropwDownRef &&
			!this.dropwDownRef.contains(e.target) &&
			!this.flagSelectorRef.contains(e.target)
		) {
			this.closeCountrySearch();
		}
	};

	toggleOpenCountrySearch = () => (this.state.isCountryListOpen ? this.closeCountrySearch() : this.openCountrySearch());

	openCountrySearch = () => {
		const openDirection =
			this.props.direction ||
			(this.inputRef &&
			this.inputRef.getBoundingClientRect().y > DROPDOWN_SIZE &&
			window.innerHeight - this.inputRef.getBoundingClientRect().y < DROPDOWN_SIZE
				? 'up'
				: 'down');
		this.setState({ isCountryListOpen: true, openDirection });
		this.countryInputRef && this.countryInputRef.focus();
	};

	closeCountrySearch = () => this.setState({ isCountryListOpen: false, countriesFilter: '' });

	selectCountry = countryCode => () => {
		this.setState({ country: countryCode }, () => this._onChange({ target: { value: this.state.inputValue } }));
		this.closeCountrySearch();
	};

	_onChange = e => {
		const { handleChange } = this.props;
		const { changed, country, inputValue } = this.state;

		const newValue = (() => {
			if (inputValue && inputValue.length === e.target.value.length + 1) {
				// Backspace
				const deletedIndex = firstDifferenceIndex(inputValue, e.target.value);
				return deleteFirstNumberBeforeIndex(inputValue, deletedIndex).replace(/\D/g, '');
			} else {
				return e.target.value.replace(/\D/g, '');
			}
		})();

		const number = '+' + countries[country].areaCode + newValue;
		const phoneNumber = parsePhoneNumberFromString(number) || {};
		phoneNumber.isValid = Boolean(phoneNumber.isValid && phoneNumber.isValid());
		phoneNumber.inputValue = removeCountryPrefixFromNumber(new AsYouType(country).input(number), country).trim();
		phoneNumber.number = phoneNumber.number || number;

		if (handleChange) handleChange(phoneNumber);
		if (!changed) this.setState({ changed: true });
	};

	_onBlur = e => {
		const { handleBlur } = this.props;
		this.setState(prevState => ({ focused: !prevState.focused }));
		if (handleBlur) return handleBlur(e);
	};

	_onFocus = e => {
		const { touched } = this.state;
		const { handleFocus } = this.props;
		this.setState(prevState => ({ focused: !prevState.focused }));
		if (!touched) this.setState({ touched: true });
		if (handleFocus) return handleFocus(e);
	};

	validate = () => {
		this.setState({ changed: true, touched: true });
	};

	resetValidation = () => {
		this.setState({ changed: false, touched: false });
	};

	render() {
		const {
			label,
			errorLabels,
			isValid,
			classes,
			size,
			helpLabels,
			className,
			disabled,
			required,
			optional,
			value, // Do not delete
			...rest
		} = this.props;
		const {
			focused,
			touched,
			changed,
			country,
			isCountryListOpen,
			countriesFilter,
			openDirection,
			inputValue,
		} = this.state;

		const showErrorMessage = errorLabels && changed && touched && !focused && !isValid;

		if (required && optional) throw new Error('Cannot use required and optional at the same time');

		const restProps = omit(rest, ['handleBlur', 'handleChange', 'handleFocus', 'complete']);

		const FlagComponent = Flags[country];

		return (
			<div className={classNames(classes.container, className)} ref={node => (this.inputRef = node)}>
				{label && (
					<div className={classes.label}>
						<Text className={classes.labelText}>{label}</Text>
						{required && <span className={classes.requiredLabelHint}>{' *'}</span>}
						{optional && (
							<Text color="grey" className={classes.optionalLabelHint}>
								{' (optional)'}
							</Text>
						)}
					</div>
				)}
				<div className={classes.inputContainer}>
					<div
						ref={node => (this.flagSelectorRef = node)}
						onClick={() => this.toggleOpenCountrySearch()}
						className={classNames(
							classes.flagSelectContainer,
							changed && touched && !focused && !isValid && classes.error,
							!focused && isValid && classes.complete,
							focused && classes.focused
						)}>
						{isMobile ? (
							<select
								className={classes.mobileSelect}
								onChange={e => this.selectCountry(e.target.value)()}
								value={country}>
								{Object.keys(countries)
									.filter(c =>
										(countries[c].name + ' +' + countries[c].areaCode)
											.toLowerCase()
											.match(countriesFilter.toLowerCase())
									)
									.sort((c1, c2) => countries[c1].name.localeCompare(countries[c2].name))
									.map(countryCode => (
										<option key={countryCode} value={countryCode}>
											{countries[countryCode].name} (+{countries[countryCode].areaCode})
										</option>
									))}
							</select>
						) : null}
						<div className={classes.flagIcon}>
							<FlagComponent style={{ height: '20px' }} title={countries[country].name} />
						</div>
						<Text color="grey" size="md" className={classes.countryCodePreview}>
							(+{countries[country].areaCode})
						</Text>
						<ArrowIcon className={classes.arrow} />
					</div>
					<input
						className={classNames(
							classes.input,
							changed && touched && !focused && !isValid && classes.error,
							!focused && isValid && classes.complete,
							focused && classes.focused,
							size && classes.customSize
						)}
						type="tel"
						placeholder={
							getExampleNumber
								? getExampleNumber(country, examples)
										.formatInternational()
										.slice(`+${countries[country].areaCode} `.length)
								: ''
						}
						value={inputValue}
						onChange={this._onChange}
						onBlur={this._onBlur}
						onFocus={this._onFocus}
						disabled={disabled}
						{...restProps}
					/>
				</div>
				<div>
					{showErrorMessage
						? errorLabels.map((error, index, arr) => (
								<Text key={error} className={classes.errorText} color="red" inline>
									{error}
									{index !== arr.length - 1 ? '. ' : ''}
								</Text>
						  ))
						: (helpLabels || [<span key={0}>&nbsp;</span>]).map((msg, index, arr) => (
								<Text key={msg} className={classes.errorText} color="grey" inline>
									{msg}
									{index !== arr.length - 1 ? '. ' : ''}
								</Text>
						  ))}
				</div>
				{!isMobile && isCountryListOpen ? (
					<div
						ref={node => (this.dropwDownRef = node)}
						className={classes.dropdownContainer}
						style={openDirection === 'up' ? { bottom: COMPONENT_HEIGHT } : {}}>
						<input
							autoFocus
							ref={node => (this.countryInputRef = node)}
							className={classes.countrySearchInput}
							placeholder="Search country..."
							onChange={e => this.setState({ countriesFilter: e.target.value })}
						/>
						<div className={classes.optionsContainer}>
							{Object.keys(countries)
								.filter(c =>
									(countries[c].name + ' +' + countries[c].areaCode).toLowerCase().match(countriesFilter.toLowerCase())
								)
								.sort((c1, c2) => countries[c1].name.localeCompare(countries[c2].name))
								.map(countryCode => {
									const Flag = Flags[countryCode];
									return (
										<div key={countryCode} className={classes.option} onClick={this.selectCountry(countryCode)}>
											<Flag className={classes.flag} title="United States" />
											<Text>
												{countries[countryCode].name} (+{countries[countryCode].areaCode})
											</Text>
										</div>
									);
								})}
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

PhoneInput.displayName = 'PhoneInput';

PhoneInput.defaultProps = {
	size: 'xl',
	defaultCountry: 'US',
};

PhoneInput.propTypes = {
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	handleBlur: PropTypes.func,
	/*
	 * Recieves an event
	 */
	handleChange: PropTypes.func.isRequired,
	handleFocus: PropTypes.func,
	errorLabels: PropTypes.arrayOf(PropTypes.string),
	isValid: PropTypes.bool.isRequired,
	classes: PropTypes.object,
	size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
	helpLabels: PropTypes.arrayOf(PropTypes.string),
	className: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	optional: PropTypes.bool,
	defaultCountry: PropTypes.string,
	direction: PropTypes.oneOf(['up', 'down']),
};

export default withNano(styles, {
	observableProps: ['size'],
})(PhoneInput);
