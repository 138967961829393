export default theme => {
	const { typography, palette } = theme;
	return {
		action: {
			gridArea: 'action',
			justifySelf: 'center',
			alignSelf: 'center',
			cursor: 'pointer',

			fontFamily: typography.fontFamily,
			fontSize: typography.fontSize,
			fontWeight: typography.fontWeightBold,
			fontStyle: typography.fontStyle,
			fontStretch: typography.fontStretch,
			lineHeight: typography.lineHeight,
			letterSpacing: typography.letterSpacing,
			textAlign: 'right',
			color: palette.blue,
			textDecoration: 'none',
		},
	};
};
