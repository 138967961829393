export default theme => {
	const { typography, palette } = theme;
	const lineHeightMap = {
		xs: typography.lineHeightXs,
		sm: typography.lineHeightSm,
		md: typography.lineHeightMd,
		lg: typography.lineHeightLg,
		xl: typography.lineHeightXl,
	};
	return {
		root: {
			fontStyle: typography.fontStyle,
			fontStretch: typography.fontStretch,
			lineHeight: typography.lineHeight,
			letterSpacing: typography.letterSpacing,
			fontWeight: typography.fontWeight,
			fontSize: typography.fontSize,
			fontFamily: typography.fontFamily,
			margin: 0,
		},
		bold: {
			fontWeight: typography.fontWeightBold,
		},
		customSize: {
			fontSize: ({ size = 12 }) => {
				if (typeof size === 'string') {
					if (size === 'sm') return typography.fontSize;
					if (size === 'md') return typography.fontSizeMd;
					if (size === 'lg') return typography.fontSizeLg;
				} else if (typeof size === 'number') {
					return size;
				}
				return typography.fontSize;
			},
		},
		customLineHeight: {
			lineHeight: ({ lineHeight = 'lg' }) => lineHeightMap[lineHeight] || lineHeightMap.lg,
		},
		underlined: {
			textDecoration: 'underline',
		},
		italic: {
			fontStyle: 'italic',
		},
		strikethrough: {
			textDecoration: 'line-through',
		},
		capitalized: {
			textTransform: 'capitalize',
		},
		uppercase: {
			textTransform: 'uppercase',
		},
		color: ({ color = 'black' }) => ({
			color: palette[color] || color,
		}),
		black: {
			color: palette.black,
		},
		grey: {
			color: palette.grey90,
		},
		white: {
			color: palette.white,
		},
		red: {
			color: palette.red,
		},
		blue: {
			color: palette.blue,
		},
	};
};
