import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import theme from '../../withTheme/theme';

const Info = props => (
	<Icon name="info" {...props} width="24" height="24" viewBox="0 0 24 24">
		{({ primary, secondary, background, broder }) => (
			<g fill={primary} fillRule="evenodd">
				{/* <path fill="#FFF" d="M0 0h24v24H0z" /> */}
				<path
					fillRule="nonzero"
					d="M11 7h2v2h-2V7zm0 4h2v6h-2v-6zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
				/>
			</g>
		)}
	</Icon>
);
const colorPropType = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.shape({
		primary: PropTypes.string,
		secondary: PropTypes.string,
		border: PropTypes.string,
		background: PropTypes.string,
	}),
]);
Info.defaultProps = {
	color: theme.palette.grey90,
	hoverColor: theme.palette.black,
	disabledColor: theme.palette.grey90,
	activeColor: theme.palette.blue,
};

Info.displayName = 'Info';

Info.propTypes = {
	color: colorPropType,
	disabledColor: colorPropType,
	activeColor: colorPropType,
	hoverColor: colorPropType,
};
export default Info;
