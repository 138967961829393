// addons/safehash.js
'use strict';

export const addon = renderer => {
	renderer.sheet = (map, _block) => {
		const block = (() => {
			if (!_block) return renderer.hash(map);
			return _block;
		})();

		return Object.keys(map).reduce((result, elementModifier) => {
			result[elementModifier] = renderer.rule(map[elementModifier], block + '-' + elementModifier);
			return result;
		}, {});
	};
};
