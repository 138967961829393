import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';

import styles from './styles';

import InfoIcon from '../../icons/Info';
import ExitIcon from '../../icons/Exit';
import Text from '../Text';

const colors = {
	warning: 'lightRed',
	info: 'blue',
	correct: 'green',
};

export const Toast = ({ label, type, classes, onClose }) => (
	<div className={classNames(classes.root, type && classes.type)}>
		<div className={classes.content}>
			<div>
				<InfoIcon color={colors[type]} className={classes.infoIcon} display="block" />
			</div>
			<Text size="md">{label}</Text>
		</div>
		<div
			className={classes.exit}
			role="button"
			tabIndex={0}
			onClick={onClose || null}
			onKeyDown={e => {
				if (e.keyCode === 'ESC' && onClose) onClose();
			}}>
			<ExitIcon className={classes.exitIcon} color="grey90" hoverColor="black" />
		</div>
	</div>
);

Toast.displayName = 'Toast';

Toast.defaultProps = {
	type: 'warning',
};

Toast.propTypes = {
	label: PropTypes.string,
	type: PropTypes.oneOf(['info', 'correct', 'warning']),
	classes: PropTypes.object,
	onClose: PropTypes.func,
};

export default withNano(styles)(Toast);
