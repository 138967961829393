import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';
import styles from './styles';

export const Header = ({
	children,
	bold,
	color = 'black',
	size,
	className,
	classes,
	underlined = false,
	italic = false,
	capitalized = false,
	uppercase = false,
	strikethrough = false,
	level = 1,
	align = 'left',
	...rest
}) => {
	const Component = `h${level}`;
	return (
		<Component
			className={classNames(
				classes.root,
				classes.level,
				align && classes.align,
				bold && classes.bold,
				color && classes[color],
				size && classes.customSize,
				underlined && classes.underlined,
				italic && classes.italic,
				capitalized && classes.capitalized,
				uppercase && classes.uppercase,
				strikethrough && classes.strikethrough,
				className
			)}
			{...rest}>
			{children}
		</Component>
	);
};

Header.displayName = 'Header';

Header.defaultProps = {};

Header.propTypes = {
	children: PropTypes.node.isRequired,
	bold: PropTypes.bool,
	// size can be "lg/md/sm" or number {12}
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	className: PropTypes.string,
	classes: PropTypes.object,
	inline: PropTypes.bool,
	color: PropTypes.oneOf(['black', 'grey', 'white', 'red']),
	align: PropTypes.oneOf(['left', 'right', 'center', 'justify', 'initial', 'inherit']),
	underlined: PropTypes.bool,
	italic: PropTypes.bool,
	strikethrough: PropTypes.bool,
	capitalized: PropTypes.bool,
	uppercase: PropTypes.bool,
	level: PropTypes.oneOf([1, 2, 3, 4]),
};

export default withNano(styles, {
	observableProps: ['size', 'level', 'align', 'bold'],
})(Header);
