export default theme => {
	const { typography, transitions } = theme;
	return {
		text: {
			border: 'none',
			backgroundColor: 'transparent',
			fontFamily: typography.fontFamily,
			fontSize: typography.fontSize,
			fontStyle: typography.fontStyle,
			fontStretch: typography.fontStretch,
			lineHeight: typography.lineHeight,
			letterSpacing: typography.letterSpacing,
			fontWeight: typography.fontWeightBold,
			cursor: 'pointer',
			outline: 'none',
			color: theme.palette.blue,
			padding: 0,
			'&:disabled': {
				color: theme.palette.grey90,
				'&:hover': {
					cursor: 'not-allowed',
				},
			},
		},

		button: {
			margin: 0,
			borderRadius: 4,
			border: 'none',
			fontFamily: typography.fontFamily,
			fontSize: typography.fontSize,
			fontStyle: typography.fontStyle,
			fontStretch: typography.fontStretch,
			lineHeight: typography.lineHeight,
			letterSpacing: typography.letterSpacing,
			fontWeight: typography.fontWeightBold,
			textAlign: 'center',
			padding: 10,
			cursor: 'pointer',
			outline: 'none',
			width: 'fit-content',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			alignContent: 'center',
			transition: `${transitions.duration.short}ms ${transitions.easing.easeOut}`,
			'&:active': {
				boxShadow: 'inset 0 1px 8px 0 rgba(0, 0, 0, 0.08)',
			},
			'&:disabled': {
				'&:hover': {
					boxShadow: 'unset',
					cursor: 'not-allowed',
				},
			},
			'& > p': {
				fontWeight: typography.fontWeightBold,
			},
		},
		secondary: {
			backgroundColor: 'transparent',
			border: `solid 1px ${theme.palette.lightBlue}`,
			'& > p': {
				color: theme.palette.blue,
			},
			color: theme.palette.blue,
			'&:active': {
				backgroundColor: 'rgba(163, 207, 255, 0.3607843137254902)',
			},
			'&:disabled': {
				borderColor: theme.palette.grey80,
				backgroundColor: theme.palette.grey80,
			},
		},
		primary: {
			backgroundColor: theme.palette.blue,
			color: theme.palette.white,
			border: `solid 1px ${theme.palette.blue}`,
			'& > p': {
				color: theme.palette.white,
			},
			'&:active': {
				backgroundColor: theme.palette.darkBlue,
			},
			'&:disabled': {
				backgroundColor: 'rgba(141, 175, 213, 0.98)',
				borderColor: 'rgba(141, 175, 213, 0.98)',
			},
		},
		size1: {
			fontSize: 8,
			padding: '5px 8px',
		},
		size2: {
			fontSize: 12,
			padding: '7px 11px',
		},
		size3: {
			fontSize: 14,
			padding: '10px 14px',
		},
		fullWidth: {
			width: '100%',
		},
		hoverEffect: {
			'&:hover': {
				boxShadow: theme.shadows[2],
			},
		},
	};
};
