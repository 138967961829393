import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import theme from '../../withTheme/theme';

const Exit = props => (
	<Icon name="exit" {...props} width="24" height="24" viewBox="0 0 24 24">
		{({ primary, secondary, background, broder }) => (
			<g fill={primary} fillRule="evenodd">
				{/* <path fill={background} d="M0 0h24v24H0z" /> */}
				<path
					fillRule="nonzero"
					d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
				/>
			</g>
		)}
	</Icon>
);

const colorPropType = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.shape({
		primary: PropTypes.string,
		secondary: PropTypes.string,
		border: PropTypes.string,
		background: PropTypes.string,
	}),
]);
Exit.defaultProps = {
	color: theme.palette.grey90,
	hoverColor: theme.palette.black,
	disabledColor: theme.palette.grey90,
	activeColor: theme.palette.blue,
};

Exit.displayName = 'Exit';

Exit.propTypes = {
	color: colorPropType,
	disabledColor: colorPropType,
	activeColor: colorPropType,
	hoverColor: colorPropType,
};

export default Exit;
