import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../withTheme/theme';
import Icon from '../Icon';

const BackArrow = props => (
	<Icon name="back-arrow" {...props}>
		{({ primary, secondary, background, border }) => (
			<g
				fill="none"
				fillRule="evenodd"
				stroke={primary}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.93">
				<path d="M18 12H8M12 18l-6-6 6-6" />
			</g>
		)}
	</Icon>
);

const colorPropType = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.shape({
		primary: PropTypes.string,
		secondary: PropTypes.string,
		border: PropTypes.string,
		background: PropTypes.string,
	}),
]);
BackArrow.defaultProps = {
	color: theme.palette.black,
	hoverColor: theme.palette.black,
	disabledColor: theme.palette.grey80,
	activeColor: theme.palette.black,
};

BackArrow.displayName = 'BackArrow';

BackArrow.propTypes = {
	color: colorPropType,
	disabledColor: colorPropType,
	activeColor: colorPropType,
	hoverColor: colorPropType,
};

export default BackArrow;
