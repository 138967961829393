import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';

const styles = ({ shape }) => ({
	root: {
		maxWidth: '100%',
		width: '100%',
	},
	rounded: {
		borderRadius: shape.borderRadius,
	},
});

export const Image = ({ src, alt, classes, className, rounded, ...rest }) => (
	<img
		src={src}
		alt={alt}
		className={classNames(classes.root, { [classes.rounded]: rounded }, className && className)}
		{...rest}
	/>
);

Image.displayName = 'Image';

Image.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	rounded: PropTypes.bool,
};

export default withNano(styles)(Image);
