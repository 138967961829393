export default theme => ({
	root: {
		// position: 'absolute',
		position: 'relative',
		left: 0,
		top: 0,
		display: 'inline-block',
	},
	label: {
		position: 'relative',
		padding: 8,
		borderRadius: '4px',
		boxShadow: '0 2px 6px 0 rgba(36, 47, 51, 0.16)',
		border: `solid 1px ${theme.palette.grey80}`,
		backgroundColor: theme.palette.white,
		maxWidth: 200,
		'&::before': {
			border: '10px solid transparent',
			content: `''`,
			display: 'block',
			position: 'absolute',
			width: 0,
		},
		'&::after': {
			border: '10px solid transparent',
			content: `''`,
			display: 'block',
			position: 'absolute',
			width: 0,
		},
	},
	left: {
		'&::before': {
			top: '10%',
			left: -10,
			borderLeft: 0,
			borderRight: `10px solid ${theme.palette.grey80}`,
		},
		'&::after': {
			top: '10%',
			left: -9,
			borderLeft: 0,
			borderRight: `10px solid ${theme.palette.white}`,
		},
	},
	bottom: {
		'&::before': {
			bottom: -10,
			left: '10%',
			borderBottom: 0,
			borderTop: `10px solid ${theme.palette.grey80}`,
		},
		'&::after': {
			bottom: -9,
			left: '10%',
			borderBottom: 0,
			borderTop: `10px solid ${theme.palette.white}`,
		},
	},
	top: {
		'&::before': {
			top: -10,
			left: '10%',
			borderTop: 0,
			borderBottom: `10px solid ${theme.palette.grey80}`,
		},
		'&::after': {
			top: -9,
			left: '10%',
			borderTop: 0,
			borderBottom: `10px solid ${theme.palette.white}`,
		},
	},
	right: {
		'&::before': {
			top: '10%',
			right: -10,
			borderRight: 0,
			borderLeft: `10px solid ${theme.palette.grey80}`,
		},
		'&::after': {
			top: '10%',
			right: -9,
			borderRight: 0,
			borderLeft: `10px solid ${theme.palette.white}`,
		},
	},
});
