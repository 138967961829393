// addons/safehash.js
'use strict';

export const addon = (renderer, _scope) => {
	const scope = _scope || '#app';
	const { putRaw } = renderer;
	renderer.putRaw = rawCssRule => {
		const scopedRawCssRule = (() => {
			if (rawCssRule[0] !== '@') return `${scope} ${rawCssRule}`;
			return rawCssRule;
		})();
		return putRaw(scopedRawCssRule);
	};
};
