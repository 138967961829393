import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';
import styles from './styles';

export const Text = React.forwardRef((props, ref) => {
	const {
		children,
		bold,
		color = 'black',
		size = 12,
		lineHeight,
		className,
		classes = {},
		inline = false,
		underlined = false,
		italic = false,
		capitalized = false,
		uppercase = false,
		strikethrough = false,
		div = false,
		...rest
	} = props;

	const Component = (() => {
		if (div) return 'div';
		return inline ? 'span' : 'p';
	})();

	return (
		<Component
			className={classNames(
				classes.root,
				bold && classes.bold,
				color && classes.color,
				size && classes.customSize,
				lineHeight && classes.customLineHeight,
				underlined && classes.underlined,
				italic && classes.italic,
				capitalized && classes.capitalized,
				uppercase && classes.uppercase,
				strikethrough && classes.strikethrough,
				className
			)}
			ref={ref}
			{...rest}>
			{children || null}
		</Component>
	);
});

Text.displayName = 'Text';

Text.defaultProps = {
	bold: false,
};

Text.propTypes = {
	children: PropTypes.node,
	bold: PropTypes.bool,
	// size can be "lg/md/sm" or number {12}
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	lineHeight: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
	className: PropTypes.string,
	classes: PropTypes.object,
	inline: PropTypes.bool,
	color: PropTypes.string,
	underlined: PropTypes.bool,
	italic: PropTypes.bool,
	strikethrough: PropTypes.bool,
	capitalized: PropTypes.bool,
	uppercase: PropTypes.bool,
	div: PropTypes.bool,
};

const TextComp = withNano(styles, {
	observableProps: ['size', 'color', 'lineHeight'],
})(Text);

export default TextComp;
