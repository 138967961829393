import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';
import omit from '../../utils/omit';
import Text from '../Text';

import styles from './styles';

export class Input extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focused: false,
			touched: false,
			changed: false,
		};
	}

	_onChange = e => {
		const { handleChange } = this.props;
		const { changed } = this.state;
		if (handleChange) handleChange(e);
		if (!changed) this.setState({ changed: true });
	};

	_onBlur = e => {
		const { handleBlur } = this.props;
		this.setState(prevState => ({ focused: !prevState.focused }));
		if (handleBlur) return handleBlur(e);
	};

	_onFocus = e => {
		const { touched } = this.state;
		const { handleFocus } = this.props;
		this.setState(prevState => ({ focused: !prevState.focused }));
		if (!touched) this.setState({ touched: true });
		if (handleFocus) return handleFocus(e);
	};

	validate = () => {
		this.setState({ changed: true, touched: true });
	};

	resetValidation = () => {
		this.setState({ changed: false, touched: false });
	};

	render() {
		const {
			label,
			placeholder,
			value,
			errorLabels,
			isValid,
			type,
			classes,
			size,
			helpLabels,
			multiline,
			className,
			disabled,
			required,
			optional,
			...rest
		} = this.props;
		const { focused, touched, changed } = this.state;
		const C = multiline ? 'textarea' : 'input';
		const showErrorMessage = errorLabels && changed && touched && !focused && !isValid;

		if (required && optional) throw new Error('Cannot use required and optional at the same time');

		const restProps = omit(rest, ['handleBlur', 'handleChange', 'handleFocus', 'complete']);

		return (
			<div className={classNames(classes.container, className)}>
				{label && (
					<div className={classes.label}>
						<Text className={classes.labelText}>{label}</Text>
						{required && <span className={classes.requiredLabelHint}>{' *'}</span>}
						{optional && (
							<Text color="grey" className={classes.optionalLabelHint}>
								{' (optional)'}
							</Text>
						)}
					</div>
				)}
				<C
					className={classNames(
						classes.input,
						changed && touched && !focused && !isValid && classes.error,
						!focused && isValid && classes.complete,
						focused && classes.focused,
						size && classes.customSize,
						multiline && classes.inputMultiline
					)}
					placeholder={placeholder}
					value={value}
					onChange={this._onChange}
					onBlur={this._onBlur}
					onFocus={this._onFocus}
					type={type}
					disabled={disabled}
					{...restProps}
				/>
				<div>
					{showErrorMessage
						? errorLabels.map((error, index, arr) => (
								<Text key={error} className={classes.errorText} color="red" inline>
									{error}
									{index !== arr.length - 1 ? '. ' : ''}
								</Text>
						  ))
						: (helpLabels || [<span key={0}>&nbsp;</span>]).map((msg, index, arr) => (
								<Text key={msg} className={classes.errorText} color="grey" inline>
									{msg}
									{index !== arr.length - 1 ? '. ' : ''}
								</Text>
						  ))}
				</div>
			</div>
		);
	}
}

Input.displayName = 'Input';

Input.defaultProps = {
	placeholder: '',
	type: 'text',
	complete: false,
	size: 'xl',
	multiline: false,
};

Input.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	handleBlur: PropTypes.func,
	/*
	 * Recieves an event
	 */
	handleChange: PropTypes.func.isRequired,
	handleFocus: PropTypes.func,
	errorLabels: PropTypes.arrayOf(PropTypes.string),
	isValid: PropTypes.bool.isRequired,
	type: PropTypes.string,
	classes: PropTypes.object,
	complete: PropTypes.bool,
	size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
	helpLabels: PropTypes.arrayOf(PropTypes.string),
	multiline: PropTypes.bool,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	optional: PropTypes.bool,
};

export default withNano(styles, {
	observableProps: ['size'],
})(Input);
