import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';

import Arrow from '../../icons/Arrow';
import Text from '../Text';
import styles from './styles';

import omit from '../../utils/omit';

export class Select extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focused: false,
			changed: false,
			touched: false,
			isOpen: false,
		};
	}

	componentDidMount = () => {
		window.addEventListener('mousedown', this.handleClickOutsideSelect, false);
	};

	componentWillUnmount = () => {
		window.removeEventListener('mousedown', this.handleClickOutsideSelect, false);
	};

	handleClickOutsideSelect = e => {
		if (this.node && !this.node.contains(e.target)) {
			this.setState({ isOpen: false });
		}
	};

	onChange = event => {
		const { handleChange } = this.props;
		const { changed } = this.state;

		this.setState({ isOpen: false });

		if (handleChange) handleChange(event);
		if (!changed) this.setState({ changed: true });
	};

	onFocus = () => {
		const { touched } = this.state;
		this.setState(prevState => ({ focused: !prevState.focused }));
		if (!touched) this.setState({ touched: true });
	};

	onBlur = () => this.setState(prevState => ({ focused: !prevState.focused }));

	toggleSelect = () => {
		const { isOpen } = this.state;
		this.setState({ isOpen: !isOpen, touched: true });
	};

	validate = () => {
		this.setState({ changed: true, touched: true, focused: false });
	};

	resetValidation = () => {
		this.setState({ changed: false, touched: false });
	};

	render = () => {
		const { options, label, errorLabels, classes, className, placeholder, isValid, value, ...rest } = this.props;
		const { touched, isOpen, focused } = this.state;

		const props = omit(rest, ['handleChange', 'fullWidth']);

		return (
			<div className={classNames(classes.container, className)} ref={node => (this.node = node)} {...props}>
				{label && <Text className={classes.label}>{label}</Text>}

				<div className={classes.inputBox}>
					<select
						className={classNames(
							classes.input,
							classes.selected,
							!value && classes.noValue,
							!focused && touched && !isValid && classes.error
						)}
						onClick={() => this.toggleSelect()}
						onChange={this.onChange}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						value={value || ''}>
						{!value && (
							<option disabled value="">
								{placeholder}
							</option>
						)}
						{options.map(({ value, label }, i) => (
							<option key={i} value={value}>
								{label}
							</option>
						))}
					</select>
					<Arrow className={classNames(classes.arrow, isOpen && classes.open)} />
				</div>

				<div>
					{errorLabels && touched && !isValid ? (
						errorLabels.map(error => (
							<Text inline key={error} className={classes.errorText} color="red">
								{error}
							</Text>
						))
					) : (
						<Text className={classes.errorText} color="red">
							<span>&nbsp;</span>
						</Text>
					)}
				</div>
			</div>
		);
	};
}

Select.displayName = 'Select';

Select.propTypes = {
	className: PropTypes.object,
	classes: PropTypes.object,
	errorLabels: PropTypes.arrayOf(PropTypes.string),
	isValid: PropTypes.bool,
	fullWidth: PropTypes.bool,
	label: PropTypes.string,
	handleChange: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		})
	),
	placeholder: PropTypes.string,
	value: PropTypes.string,
};

export default withNano(styles, { observableProps: ['fullWidth'] })(Select);
