import React from 'react';
import PropTypes from 'prop-types';

import theme from '../../withTheme/theme';
import Icon from '../Icon';

const CheckSimple = props => (
	<Icon name="check" {...props}>
		{({ primary, secondary, background, broder }) => (
			<g fill={primary} fillRule="evenodd">
				<path fillRule="nonzero" d="M8.6 15.6l-4.2-4.2L3 12.8l5.6 5.6 12-12L19.2 5z" />
			</g>
		)}
	</Icon>
);

const colorPropType = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.shape({
		primary: PropTypes.string,
		secondary: PropTypes.string,
		border: PropTypes.string,
		background: PropTypes.string,
	}),
]);
CheckSimple.defaultProps = {
	color: theme.palette.grey90,
	hoverColor: theme.palette.black,
	disabledColor: theme.palette.grey90,
	activeColor: theme.palette.blue,
};

CheckSimple.displayName = 'CheckSimple';

CheckSimple.propTypes = {
	color: colorPropType,
	disabledColor: colorPropType,
	activeColor: colorPropType,
	hoverColor: colorPropType,
};

export default CheckSimple;
