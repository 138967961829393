import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';

import styles from './styles';

// export interface ButtonProps extends WithNanoProps {
// 	children?: React.ReactNode;
// 	onClick?: (e: React.MouseEvent<HTMLElement>) => any;
// 	secondary?: boolean;
// 	primary?: boolean;
// 	size?: 1 | 2 | 3;
// 	className?: string;
// 	fullWidth?: boolean;
// 	hoverEffect?: boolean;
// 	submit?: boolean;
// 	type?: 'button' | 'text';
// }

export const Button = ({
	children,
	onClick,
	secondary,
	primary,
	text,
	size,
	classes,
	className,
	fullWidth,
	hoverEffect,
	type,
	submit,
	...rest
}) => {
	const getSizeClass = s => {
		if (s === 1) return classes.size1;
		if (s === 2) return classes.size2;
		if (s === 3) return classes.size3;
		return classes.size2;
	};

	return (
		// eslint-disable-next-line react/button-has-type
		<button
			type={submit ? 'submit' : 'button'}
			onClick={onClick}
			className={classNames(
				type === 'button' && classes.button,
				type === 'text' && classes.text,
				classes.app,
				type === 'button' && secondary && classes.secondary,
				type === 'button' && !secondary && text && classes.text,
				type === 'button' && !secondary && primary && classes.primary,
				size && type === 'button' && !text && getSizeClass(size),
				fullWidth && classes.fullWidth,
				type === 'button' && hoverEffect && (secondary || primary || !text) && classes.hoverEffect,
				className
			)}
			{...rest}>
			{children}
		</button>
	);
};

Button.displayName = 'Button';

Button.defaultProps = {
	type: 'button',
	size: 2,
	hoverEffect: true,
	primary: true,
};

Button.propTypes = {
	children: PropTypes.node,
	onClick: PropTypes.func.isRequired,
	secondary: PropTypes.bool,
	primary: PropTypes.bool,
	text: PropTypes.bool,
	size: PropTypes.number,
	classes: PropTypes.object,
	className: PropTypes.string,
	fullWidth: PropTypes.bool,
	hoverEffect: PropTypes.bool,
	type: PropTypes.oneOf(['button', 'text']),
	submit: PropTypes.bool,
};

export default withNano(styles)(Button);
