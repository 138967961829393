import { SPACINGS, GRID_SIZES } from './constants';

const makeRules = size => {
	if (size === true) {
		return {
			flexBasis: 0,
			flexGrow: 1,
			maxWidth: '100%',
		};
	}

	if (size === 'auto') {
		return {
			flexBasis: 'auto',
			flexGrow: 0,
			maxWidth: 'none',
		};
	}

	const width = `${Math.round((size / 12) * 10e7) / 10e5}%`;

	return {
		flexBasis: width,
		flexGrow: 0,
		maxWidth: width,
	};
};

export const makeGrid = theme =>
	theme.breakpoints.keys.reduce(
		(memo, breakpoint) =>
			Object.assign(
				memo,
				GRID_SIZES.reduce((finalClasses, size) => {
					const key = `grid-${breakpoint}-${size}`;
					if (breakpoint === 'xs') {
						finalClasses[key] = makeRules(size);
					} else {
						finalClasses[key] = {
							[theme.breakpoints.up(breakpoint)]: makeRules(size),
						};
					}
					return finalClasses;
				}, {})
			),
		{}
	);

function getOffset(val, div = 1) {
	const parse = parseFloat(val);
	return `${parse / div}${String(val).replace(String(parse), '') || 'px'}`;
}

export function generateGutter(theme, breakpoint) {
	const customStyles = {};

	SPACINGS.forEach(spacing => {
		const themeSpacing = theme.spacing(spacing, 8);

		if (themeSpacing === 0) return;

		customStyles[`spacing-${breakpoint}-${spacing}`] = {
			'& > $item': {
				padding: getOffset(themeSpacing, 2),
			},
			'& > $noPaddingX': {
				paddingLeft: 0,
				paddingRight: 0,
			},
			'& > $noPaddingBottom': {
				paddingBottom: 0,
			},
			'$ > noPadding': {
				padding: 0,
			},
			'$ > marginTop': {
				marginTop: themeSpacing,
			},
			'$> marginBottom': {
				marginBottom: themeSpacing,
			},
			'$ > marginY': {
				marginTop: themeSpacing,
				marginBottom: themeSpacing,
			},
		};
	});

	return customStyles;
}
