import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';
import styles from './styles';

export const Container = ({ children, classes, className, ...rest }) => (
	<div className={classNames(classes.container, className)} {...rest}>
		{children}
	</div>
);

Container.displayName = 'Container';

Container.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	classes: PropTypes.object,
	className: PropTypes.string,
};

export default withNano(styles)(Container);
