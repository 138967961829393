const countriesData = {
	AW: {
		name: 'Aruba',
		areaCode: 297,
	},
	AF: {
		name: 'Afghanistan',
		areaCode: 93,
	},
	AO: {
		name: 'Angola',
		areaCode: 244,
	},
	AI: {
		name: 'Anguilla',
		areaCode: 1,
	},
	AL: {
		name: 'Albania',
		areaCode: 355,
	},
	AD: {
		name: 'Andorra',
		areaCode: 376,
	},
	AE: {
		name: 'United Arab Emirates',
		areaCode: 971,
	},
	AR: {
		name: 'Argentina',
		areaCode: 54,
	},
	AM: {
		name: 'Armenia',
		areaCode: 374,
	},
	AS: {
		name: 'American Samoa',
		areaCode: 1,
	},
	AG: {
		name: 'Antigua and Barbuda',
		areaCode: 1,
	},
	AU: {
		name: 'Australia',
		areaCode: 61,
	},
	AT: {
		name: 'Austria',
		areaCode: 43,
	},
	AZ: {
		name: 'Azerbaijan',
		areaCode: 994,
	},
	BI: {
		name: 'Burundi',
		areaCode: 257,
	},
	BE: {
		name: 'Belgium',
		areaCode: 32,
	},
	BJ: {
		name: 'Benin',
		areaCode: 229,
	},
	BF: {
		name: 'Burkina Faso',
		areaCode: 226,
	},
	BD: {
		name: 'Bangladesh',
		areaCode: 880,
	},
	BG: {
		name: 'Bulgaria',
		areaCode: 359,
	},
	BH: {
		name: 'Bahrain',
		areaCode: 973,
	},
	CA: {
		name: 'Canada',
		areaCode: 1,
	},
	BS: {
		name: 'Bahamas',
		areaCode: 1,
	},
	BA: {
		name: 'Bosnia and Herzegovina',
		areaCode: 387,
	},
	BY: {
		name: 'Belarus',
		areaCode: 375,
	},
	BZ: {
		name: 'Belize',
		areaCode: 501,
	},
	BM: {
		name: 'Bermuda',
		areaCode: 1,
	},
	BO: {
		name: 'Bolivia',
		areaCode: 591,
	},
	BR: {
		name: 'Brazil',
		areaCode: 55,
	},
	BB: {
		name: 'Barbados',
		areaCode: 1,
	},
	BN: {
		name: 'Brunei',
		areaCode: 673,
	},
	BT: {
		name: 'Bhutan',
		areaCode: 975,
	},
	BW: {
		name: 'Botswana',
		areaCode: 267,
	},
	CF: {
		name: 'Central African Republic',
		areaCode: 236,
	},
	CH: {
		name: 'Switzerland',
		areaCode: 41,
	},
	CL: {
		name: 'Chile',
		areaCode: 56,
	},
	CN: {
		name: 'China',
		areaCode: 86,
	},
	CI: {
		name: "Côte D'Ivoire",
		areaCode: 225,
	},
	CM: {
		name: 'Cameroon',
		areaCode: 237,
	},
	CG: {
		name: 'Congo Brazzaville',
		areaCode: 242,
	},
	CK: {
		name: 'Cook Islands',
		areaCode: 682,
	},
	CO: {
		name: 'Colombia',
		areaCode: 57,
	},
	CV: {
		name: 'Cape Verde',
		areaCode: 238,
	},
	CR: {
		name: 'Costa Rica',
		areaCode: 506,
	},
	CU: {
		name: 'Cuba',
		areaCode: 53,
	},
	KY: {
		name: 'Cayman Islands',
		areaCode: 1,
	},
	CY: {
		name: 'Cyprus',
		areaCode: 357,
	},
	CZ: {
		name: 'Czech Republic',
		areaCode: 420,
	},
	DE: {
		name: 'Germany',
		areaCode: 49,
	},
	DJ: {
		name: 'Djibouti',
		areaCode: 253,
	},
	DM: {
		name: 'Dominica',
		areaCode: 1,
	},
	DK: {
		name: 'Denmark',
		areaCode: 45,
	},
	DO: {
		name: 'Dominican Republic',
		areaCode: 1,
	},
	DZ: {
		name: 'Algeria',
		areaCode: 213,
	},
	EC: {
		name: 'Ecuador',
		areaCode: 593,
	},
	EG: {
		name: 'Egypt',
		areaCode: 20,
	},
	ER: {
		name: 'Eritrea',
		areaCode: 291,
	},
	ES: {
		name: 'Spain',
		areaCode: 34,
	},
	EE: {
		name: 'Estonia',
		areaCode: 372,
	},
	ET: {
		name: 'Ethiopia',
		areaCode: 251,
	},
	FI: {
		name: 'Finland',
		areaCode: 358,
	},
	FJ: {
		name: 'Fiji',
		areaCode: 679,
	},
	FK: {
		name: 'Falkland Islands (Malvinas)',
		areaCode: 500,
	},
	FR: {
		name: 'France',
		areaCode: 33,
	},
	FO: {
		name: 'Faroe Islands',
		areaCode: 298,
	},
	FM: {
		name: 'Micronesia',
		areaCode: 691,
	},
	GA: {
		name: 'Gabon',
		areaCode: 241,
	},
	GB: {
		name: 'United Kingdom',
		areaCode: 44,
	},
	GE: {
		name: 'Georgia',
		areaCode: 995,
	},
	GH: {
		name: 'Ghana',
		areaCode: 233,
	},
	GN: {
		name: 'Guinea',
		areaCode: 224,
	},
	GM: {
		name: 'Gambia',
		areaCode: 220,
	},
	GW: {
		name: 'Guinea-Bissau',
		areaCode: 245,
	},
	GQ: {
		name: 'Equatorial Guinea',
		areaCode: 240,
	},
	GR: {
		name: 'Greece',
		areaCode: 30,
	},
	GD: {
		name: 'Grenada',
		areaCode: 1,
	},
	GL: {
		name: 'Greenland',
		areaCode: 299,
	},
	GT: {
		name: 'Guatemala',
		areaCode: 502,
	},
	GY: {
		name: 'Guyana',
		areaCode: 592,
	},
	HK: {
		name: 'Hong Kong',
		areaCode: 852,
	},
	HN: {
		name: 'Honduras',
		areaCode: 504,
	},
	HR: {
		name: 'Croatia',
		areaCode: 385,
	},
	HT: {
		name: 'Haiti',
		areaCode: 509,
	},
	HU: {
		name: 'Hungary',
		areaCode: 36,
	},
	ID: {
		name: 'Indonesia',
		areaCode: 62,
	},
	IM: {
		name: 'Isle of Man',
		areaCode: 44,
	},
	IN: {
		name: 'India',
		areaCode: 91,
	},
	IE: {
		name: 'Ireland',
		areaCode: 353,
	},
	IR: {
		name: 'Iran',
		areaCode: 98,
	},
	IQ: {
		name: 'Iraq',
		areaCode: 964,
	},
	IS: {
		name: 'Iceland',
		areaCode: 354,
	},
	IL: {
		name: 'Israel',
		areaCode: 972,
	},
	IT: {
		name: 'Italy',
		areaCode: 39,
	},
	JM: {
		name: 'Jamaica',
		areaCode: 1,
	},
	JE: {
		name: 'Jersey',
		areaCode: 44,
	},
	JO: {
		name: 'Jordan',
		areaCode: 962,
	},
	JP: {
		name: 'Japan',
		areaCode: 81,
	},
	KZ: {
		name: 'Kazakhstan',
		areaCode: 7,
	},
	KE: {
		name: 'Kenya',
		areaCode: 254,
	},
	KG: {
		name: 'Kyrgyzstan',
		areaCode: 996,
	},
	KH: {
		name: 'Cambodia',
		areaCode: 855,
	},
	KI: {
		name: 'Kiribati',
		areaCode: 686,
	},
	KN: {
		name: 'Saint Kitts and Nevis',
		areaCode: 1,
	},
	KR: {
		name: 'South Korea',
		areaCode: 82,
	},
	KP: {
		name: 'North Korea',
		areaCode: 850,
	},
	XK: {
		name: 'Kosovo',
		areaCode: 383,
	},
	KW: {
		name: 'Kuwait',
		areaCode: 965,
	},
	LA: {
		name: 'Laos',
		areaCode: 856,
	},
	LB: {
		name: 'Lebanon',
		areaCode: 961,
	},
	LR: {
		name: 'Liberia',
		areaCode: 231,
	},
	LY: {
		name: 'Libya',
		areaCode: 218,
	},
	LC: {
		name: 'Saint Lucia',
		areaCode: 1,
	},
	LI: {
		name: 'Liechtenstein',
		areaCode: 423,
	},
	LK: {
		name: 'Sri Lanka',
		areaCode: 94,
	},
	LS: {
		name: 'Lesotho',
		areaCode: 266,
	},
	LT: {
		name: 'Lithuania',
		areaCode: 370,
	},
	LU: {
		name: 'Luxembourg',
		areaCode: 352,
	},
	LV: {
		name: 'Latvia',
		areaCode: 371,
	},
	MO: {
		name: 'Macao',
		areaCode: 853,
	},
	MA: {
		name: 'Morocco',
		areaCode: 212,
	},
	MC: {
		name: 'Monaco',
		areaCode: 377,
	},
	MD: {
		name: 'Moldova',
		areaCode: 373,
	},
	MG: {
		name: 'Madagascar',
		areaCode: 261,
	},
	MV: {
		name: 'Maldives',
		areaCode: 960,
	},
	MX: {
		name: 'Mexico',
		areaCode: 52,
	},
	MK: {
		name: 'North Macedonia',
		areaCode: 389,
	},
	ML: {
		name: 'Mali',
		areaCode: 223,
	},
	MT: {
		name: 'Malta',
		areaCode: 356,
	},
	MM: {
		name: 'Myanmar',
		areaCode: 95,
	},
	ME: {
		name: 'Montenegro',
		areaCode: 382,
	},
	MN: {
		name: 'Mongolia',
		areaCode: 976,
	},
	MP: {
		name: 'Northern Mariana Islands',
		areaCode: 1,
	},
	MZ: {
		name: 'Mozambique',
		areaCode: 258,
	},
	MR: {
		name: 'Mauritania',
		areaCode: 222,
	},
	MS: {
		name: 'Montserrat',
		areaCode: 1,
	},
	MU: {
		name: 'Mauritius',
		areaCode: 230,
	},
	MW: {
		name: 'Malawi',
		areaCode: 265,
	},
	MY: {
		name: 'Malaysia',
		areaCode: 60,
	},
	NA: {
		name: 'Namibia',
		areaCode: 264,
	},
	NC: {
		name: 'New Caledonia',
		areaCode: 687,
	},
	NE: {
		name: 'Niger',
		areaCode: 227,
	},
	NG: {
		name: 'Nigeria',
		areaCode: 234,
	},
	NI: {
		name: 'Nicaragua',
		areaCode: 505,
	},
	NL: {
		name: 'Netherlands',
		areaCode: 31,
	},
	NO: {
		name: 'Norway',
		areaCode: 47,
	},
	NP: {
		name: 'Nepal',
		areaCode: 977,
	},
	NR: {
		name: 'Nauru',
		areaCode: 674,
	},
	NZ: {
		name: 'New Zealand',
		areaCode: 64,
	},
	OM: {
		name: 'Oman',
		areaCode: 968,
	},
	PK: {
		name: 'Pakistan',
		areaCode: 92,
	},
	PA: {
		name: 'Panama',
		areaCode: 507,
	},
	PE: {
		name: 'Peru',
		areaCode: 51,
	},
	PH: {
		name: 'Philippines',
		areaCode: 63,
	},
	PW: {
		name: 'Palau',
		areaCode: 680,
	},
	PG: {
		name: 'Papua New Guinea',
		areaCode: 675,
	},
	PL: {
		name: 'Poland',
		areaCode: 48,
	},
	PR: {
		name: 'Puerto Rico',
		areaCode: 1,
	},
	PT: {
		name: 'Portugal',
		areaCode: 351,
	},
	PY: {
		name: 'Paraguay',
		areaCode: 595,
	},
	PS: {
		name: 'State of Palestine',
		areaCode: 970,
	},
	PF: {
		name: 'French Polynesia',
		areaCode: 689,
	},
	QA: {
		name: 'Qatar',
		areaCode: 974,
	},
	RO: {
		name: 'Romania',
		areaCode: 40,
	},
	RU: {
		name: 'Russia',
		areaCode: 7,
	},
	RW: {
		name: 'Rwanda',
		areaCode: 250,
	},
	EH: {
		name: 'Western Sahara',
		areaCode: 212,
	},
	SA: {
		name: 'Saudi Arabia',
		areaCode: 966,
	},
	SD: {
		name: 'Sudan',
		areaCode: 249,
	},
	SN: {
		name: 'Senegal',
		areaCode: 221,
	},
	SG: {
		name: 'Singapore',
		areaCode: 65,
	},
	SH: {
		name: 'Saint Helena',
		areaCode: 290,
	},
	SB: {
		name: 'Solomon Islands',
		areaCode: 677,
	},
	SL: {
		name: 'Sierra Leone',
		areaCode: 232,
	},
	SV: {
		name: 'El Salvador',
		areaCode: 503,
	},
	SM: {
		name: 'San Marino',
		areaCode: 378,
	},
	SO: {
		name: 'Somalia',
		areaCode: 252,
	},
	PM: {
		name: 'Saint Pierre and Miquelon',
		areaCode: 508,
	},
	RS: {
		name: 'Serbia',
		areaCode: 381,
	},
	ST: {
		name: 'Suriname',
		areaCode: 239,
	},
	SK: {
		name: 'Slovakia',
		areaCode: 421,
	},
	SI: {
		name: 'Slovenia:',
		areaCode: 386,
	},
	SE: {
		name: 'Sweden',
		areaCode: 46,
	},
	SZ: {
		name: 'Eswatini',
		areaCode: 268,
	},
	SC: {
		name: 'Seychelles',
		areaCode: 248,
	},
	SY: {
		name: 'Syria',
		areaCode: 963,
	},
	TC: {
		name: 'Turks and Caicos Islands',
		areaCode: 1,
	},
	TD: {
		name: 'Chad',
		areaCode: 235,
	},
	TG: {
		name: 'Togo',
		areaCode: 228,
	},
	TH: {
		name: 'Thailand',
		areaCode: 66,
	},
	TJ: {
		name: 'Tajikistan',
		areaCode: 992,
	},
	TM: {
		name: 'Turkmenistan',
		areaCode: 993,
	},
	TO: {
		name: 'Tonga',
		areaCode: 676,
	},
	TT: {
		name: 'Trinidad and Tobago',
		areaCode: 1,
	},
	TN: {
		name: 'Tunisia',
		areaCode: 216,
	},
	TR: {
		name: 'Turkey',
		areaCode: 90,
	},
	TW: {
		name: 'Taiwan',
		areaCode: 886,
	},
	TZ: {
		name: 'Tanzania',
		areaCode: 255,
	},
	UG: {
		name: 'Uganda',
		areaCode: 256,
	},
	UA: {
		name: 'Ukraine',
		areaCode: 380,
	},
	UY: {
		name: 'Uruguay',
		areaCode: 598,
	},
	US: {
		name: 'United States',
		areaCode: 1,
	},
	UZ: {
		name: 'Uzbekistan',
		areaCode: 998,
	},
	VA: {
		name: 'Holy See (Vatican City State)',
		areaCode: 39,
	},
	VC: {
		name: 'Saint Vincent and The Grenadines',
		areaCode: 1,
	},
	VE: {
		name: 'Venezuela',
		areaCode: 58,
	},
	VN: {
		name: 'Vietnam',
		areaCode: 84,
	},
	VU: {
		name: 'Vanuatu',
		areaCode: 678,
	},
	WF: {
		name: 'Wallis and Futuna',
		areaCode: 681,
	},
	WS: {
		name: 'Samoa',
		areaCode: 685,
	},
	YE: {
		name: 'Yemen',
		areaCode: 967,
	},
	ZA: {
		name: 'South Africa',
		areaCode: 27,
	},
	ZM: {
		name: 'Zambia',
		areaCode: 260,
	},
	ZW: {
		name: 'Zimbabwe',
		areaCode: 263,
	},
};

export default countriesData;
