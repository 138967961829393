export default theme => ({
	root: {
		display: 'flex',
		padding: '8px 16px',
		position: 'sticky',
		borderTop: 'solid 1px #d3d3db',
		justifyContent: 'space-between',
		bottom: 0,
		borderRadius: 4,
		boxShadow: `0 2px 8px 0 rgba(36, 47, 51, 0.1)`,
		border: `solid 1px ${theme.palette.grey80}`,
		backgroundColor: theme.palette.white,
	},
	type: {
		borderLeft: ({ type }) => {
			if (type === 'info') return `solid 4px ${theme.palette.blue}`;
			if (type === 'correct') return `solid 4px ${theme.palette.green}`;
			if (type === 'warning') return `solid 4px ${theme.palette.lightRed}`;
		},
	},
	infoIcon: {
		marginRight: 10,
	},
	content: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	exit: {
		outline: 'none',
	},
});
