import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';
import styles from './styles';

import CheckSimple from '../../icons/CheckSimple';

export const CheckBox = ({ selected, onClick, classes, className, ...rest }) => (
	<div className={classNames(className, classes.container)} {...rest}>
		<div
			className={classNames(selected && classes.selected, classes.radio)}
			onClick={() => {
				if (onClick) onClick(!selected);
			}}>
			{selected && <CheckSimple color="white" size={16} />}
		</div>
	</div>
);

CheckBox.displayName = 'CheckBox';

CheckBox.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	onClick: PropTypes.func,
	selected: PropTypes.bool,
};

export default withNano(styles)(CheckBox);
