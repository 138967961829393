export default theme => {
	const { typography, palette, transitions, spacing } = theme;
	return {
		input: {
			width: 'calc(100% - 18px)',
			borderRadius: 4,
			border: `solid 1px ${palette.grey90}`,
			height: 20,
			padding: spacing(2),
			outline: 'none',
			transition: `${transitions.duration.short}ms ${transitions.easing.easeOut}`,
			fontSize: typography.fontSizeMd,
			fontWeight: typography.fontWeight,
			fontStyle: typography.fontStyle,
			fontStretch: typography.fontStretch,
			fontFamily: typography.fontFamily,
			lineHeight: typography.lineHeightMd,
			letterSpacing: 'normal',
			color: palette.black,
			boxSizing: 'content-box',
			'&::placeholder': {
				color: palette.grey70,
			},
			'&[type=text]': {
				'-webkit-appearance': 'none',
				'-moz-appearance': 'none',
				appearance: 'none',
			},
		},
		'input:disabled': {
			background: '#eaeaea',
			cursor: 'no-drop',
			border: 'none',
		},
		focused: {
			border: `solid 1px ${palette.blue}`,
		},
		error: {
			backgroundColor: 'rgba(208, 2, 27, 0.08)',
			border: `solid 1px ${palette.red}`,
		},
		complete: {
			border: `solid 1px ${palette.black}`,
		},
		container: {
			margin: `${spacing(1)} 0`,
		},
		errorText: {},
		label: {
			lineHeight: typography.lineHeightLg,
			letterSpacing: 'normal',
		},
		labelText: {
			display: 'inline-block',
		},
		customSize: {
			width: ({ size }) => {
				if (typeof size === 'string') {
					if (size === 'sm') return 'calc(25% - 18px)';
					if (size === 'md') return 'calc(50% - 18px)';
					if (size === 'lg') return 'calc(80% - 18px)';
					if (size === 'xl') return 'calc(100% - 18px)';
				}
			},
		},
		inputMultiline: {
			width: 'calc(100% - 18px)',
			height: 'auto',
			maxWidth: '100%',
		},
		requiredLabelHint: {
			verticalAlign: 'baseline',
			color: 'red',
		},
		optionalLabelHint: {
			display: 'inline',
			marginLeft: '4px',
		},
	};
};
