import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withNano from '../../withNano';
import styles from './styles';

export const Link = ({ children, classes, className, newTab, to, ...rest }) => (
	<a href={to} className={classNames(classes.action, className)} target={newTab ? '_blank' : '_self'} {...rest}>
		{children}
	</a>
);

Link.displayName = 'Link';

Link.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	classes: PropTypes.object,
	className: PropTypes.string,
	newTab: PropTypes.bool,
	to: PropTypes.string.isRequired,
};

export default withNano(styles)(Link);
