export default theme => {
	const { palette } = theme;

	return {
		container: {
			display: 'flex',
			alignContent: 'center',
		},

		radio: {
			appearance: 'none',
			display: 'inline',
			backgroundColor: '#f1f1f1',
			color: '#666',
			top: '10px',
			margin: '3px 0',
			height: '16px',
			width: '16px',
			border: 0,
			borderRadius: '4px',
			cursor: 'pointer',
			marginRight: '7px',
			outline: 'none',
		},

		selected: {
			backgroundColor: `${palette.blue} !important`,
		},

		label: {
			display: 'inline',
		},
	};
};
