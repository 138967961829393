import { generateGutter, makeGrid } from './helpers';

export default theme => ({
	root: {},
	container: {
		boxSizing: 'border-box',
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
	},
	item: {
		boxSizing: 'border-box',
		margin: '0',
	},
	zeroMinWidth: {
		minWidth: 0,
	},
	'direction-xs-column': {
		flexDirection: 'column',
	},
	'direction-xs-column-reverse': {
		flexDirection: 'column-reverse',
	},
	'direction-xs-row-reverse': {
		flexDirection: 'row-reverse',
	},
	'wrap-xs-nowrap': {
		flexWrap: 'nowrap',
	},
	'wrap-xs-wrap-reverse': {
		flexWrap: 'wrap-reverse',
	},
	'align-items-xs-center': {
		alignItems: 'center',
	},
	'align-items-xs-flex-start': {
		alignItems: 'flex-start',
	},
	'align-items-xs-flex-end': {
		alignItems: 'flex-end',
	},
	'align-items-xs-baseline': {
		alignItems: 'baseline',
	},
	'align-content-xs-center': {
		alignContent: 'center',
	},
	'align-content-xs-flex-start': {
		alignContent: 'flex-start',
	},
	'align-content-xs-flex-end': {
		alignContent: 'flex-end',
	},
	'align-content-xs-space-between': {
		alignContent: 'space-between',
	},
	'align-content-xs-space-around': {
		alignContent: 'space-around',
	},
	'justify-xs-center': {
		justifyContent: 'center',
	},
	'justify-xs-flex-end': {
		justifyContent: 'flex-end',
	},
	'justify-xs-space-between': {
		justifyContent: 'space-between',
	},
	'justify-xs-space-around': {
		justifyContent: 'space-around',
	},
	'justify-xs-space-evenly': {
		justifyContent: 'space-evenly',
	},
	...generateGutter(theme, 'xs'),
	...makeGrid(theme),
	noPaddingX: {},
});
