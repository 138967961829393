export default theme => {
	const { typography, palette } = theme;
	return {
		root: {
			fontStyle: typography.fontStyle,
			fontStretch: typography.fontStretch,
			lineHeight: typography.lineHeight,
			letterSpacing: typography.letterSpacing,
			fontWeight: typography.fontWeight,
			fontSize: typography.fontSize,
			fontFamily: typography.fontFamily,
			margin: 0,
		},
		customSize: {
			fontSize: ({ size }) => {
				if (typeof size === 'string') {
					if (size === 'sm') return typography.fontSize;
					if (size === 'md') return typography.fontSizeMd;
					if (size === 'lg') return typography.fontSizeLg;
				} else if (typeof size === 'number') {
					return size;
				}
				return typography.fontSize;
			},
		},
		level: ({ level = 1, bold = false, size }) => ({
			fontSize: size ? null : typography[`h${level}`].fontSize,
			fontWeight: bold ? typography.fontWeightBold : typography[`h${level}`].fontWeight,
		}),
		align: {
			textAlign: ({ align }) => align || 'left',
		},
		underlined: {
			textDecoration: 'underline',
		},
		bold: {
			fontWeight: typography.fontWeightBold,
		},
		italic: {
			fontStyle: 'italic',
		},
		strikethrough: {
			textDecoration: 'line-through',
		},
		capitalized: {
			textTransform: 'capitalize',
		},
		uppercase: {
			textTransform: 'uppercase',
		},
		black: {
			color: palette.black,
		},
		grey: {
			color: palette.grey90,
		},
		white: {
			color: palette.white,
		},
		red: {
			color: palette.red,
		},
	};
};
