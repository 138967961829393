import NanoCSS from 'nano-css';
import { addon as addonCache } from 'nano-css/addon/cache';
import { addon as addonStable } from 'nano-css/addon/stable';
import { addon as addonNesting } from 'nano-css/addon/nesting';
import { addon as addonKeyframes } from 'nano-css/addon/keyframes';
import { addon as addonUnitless } from 'nano-css/addon/unitless';
// import { addon as addonRule } from 'nano-css/addon/rule';
// import { addon as addonSheet } from 'nano-css/addon/sheet';
import { addon as addonPrefixer } from 'nano-css/addon/prefixer';

import { addon as addonSafeHash } from './addons/safehash';
import { addon as addonSheet } from './addons/sheet';
import { addon as addonRule } from './addons/rule';

const ES_STYLESHEET_ID = 'es-nano-css-stylesheet';
const ES_CREATOR_ID = 'es-with-nano';

const nano = NanoCSS.create({
	// Add prefix to all generated class names.
	pfx: 'es-',

	sh: (() => {
		if (typeof window === 'undefined') return;
		const memo = {};
		const elem = document.getElementById(ES_STYLESHEET_ID);
		if (elem) {
			if (elem.creator === ES_CREATOR_ID) return elem;
			const insertRule = elem.sheet.insertRule;
			elem.sheet.insertRule = function(...args) {
				const params = [].slice.apply(args);
				const rawCSSRule = params[0];
				const cacheKey = rawCSSRule.replace(/\s/g, '');
				if (memo[cacheKey]) return;
				memo[cacheKey] = 1;
				insertRule.apply(this, args);
			};
			elem.creator = ES_CREATOR_ID;
			return elem;
		}

		const style = document.createElement('style');
		style.creator = ES_CREATOR_ID;
		style.id = ES_STYLESHEET_ID;
		document.getElementsByTagName('head')[0].appendChild(style);
		const insertRule = style.sheet.insertRule;
		style.sheet.insertRule = function(...args) {
			const params = [].slice.apply(args);
			const rawCSSRule = params[0];
			const cacheKey = rawCSSRule.replace(/\s/g, '');
			if (memo[cacheKey]) return;
			memo[cacheKey] = 1;
			insertRule.apply(this, args);
		};
		return style;

		// if (process.env.NODE_ENV === 'production') {
		// 	style.creator;
		// } else {
		// 	const appendChild = style.appendChild;
		// 	style.appendChild = function(...args) {
		// 		const params = [].slice.apply(args);
		// 		const textNode = params[0];
		// 		const cacheKey = textNode.textContent.replace(/\s/g, '');
		// 		if (memo[cacheKey]) return;
		// 		memo[cacheKey] = 1;
		// 		appendChild.apply(this, args);
		// 	};
		// }
		// return style;
	})(),
});

if (nano.client) {
	nano.putRaw = rawCSSRule => {
		if (!nano.sh) return;
		const sheet = nano.sh.sheet;
		// Unknown pseudo-selectors will throw, this try/catch swallows all errors.
		try {
			sheet.insertRule(rawCSSRule, sheet.cssRules.length);
			// eslint-disable-next-line no-empty
		} catch (error) {}
	};
}

// Add addons you would like to use.
addonSafeHash(nano);

addonCache(nano);
addonStable(nano);
addonNesting(nano);
addonRule(nano);
addonSheet(nano);
addonKeyframes(nano);
addonUnitless(nano);
addonPrefixer(nano);

export default nano;
